.calender-summary-wrapper {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	height: fit-content;
}
.widget-calender {
	flex: 1;
	background-color: #fff;
	border-radius: $border-radius;
	padding: 3rem 1rem;
	height: fit-content;
	p {
		margin-bottom: 1.5rem;
	}
	.price-per-booking {
		padding-bottom: 24px;
		font-weight: 400;
		span{
			font-family: "public-font-medium" !important;
			font-weight: 600 !important;
		}
		.border-b {
			border-bottom: 1px solid #ddd;
			padding-bottom: 2rem;
		}
	}
	.react-calendar {
		width: 100%;
		border-radius: 12px;
		border: 1px solid #ddd;
		background: #f1f5f9;
		overflow: hidden;
		min-height: 27rem;
		margin-bottom: 2rem;
		// .react-calendar__tile{
		// 	margin: unset !important;
		// }
		.react-calendar__navigation {
			.react-calendar__navigation__prev2-button,
			.react-calendar__navigation__next2-button {
				display: none;
			}
			.react-calendar__navigation__arrow {
				font-weight: 700;
				&:focus {
					background-color: transparent;
				}
				&:hover {
					background-color: #e6e6e6;
				}
			}
			.react-calendar__navigation__label {
				font-weight: 700;
				color: #000;
				pointer-events: none !important;
				&:hover,
				&:focus {
					background-color: transparent;
				}
			}
		}
		.react-calendar__viewContainer {
			font-family: "public-font" !important;
			.react-calendar__month-view__weekdays {
				color: #b9b9bb;
				font-weight: 600;
				padding-inline: 1rem;
				display: grid !important;
				grid-template-columns: repeat(7, 1fr);
				abbr {
					text-decoration: none;
					font-size: 0.65rem;
				}
			}
			.react-calendar__month-view__days {
				padding: 1rem;
				display: grid !important;
				grid-template-columns: repeat(7, 1fr);
				button:disabled {
					opacity: 0.5;
					cursor: not-allowed;
					background-color: unset;
					abbr {
						background-color: unset !important;
						text-decoration: line-through;
					}
				}
				.react-calendar__tile {
					font-family: "public-font" !important;
					padding: 0;
					font-size: 0.75rem;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: 600;
					color: #6a6a6d;
					flex: unset !important;
					&.react-calendar__tile--active {
						background-color: transparent;
						color: #fff;
						abbr {
							background-color: #005cbb !important;
						}
					}
					abbr {
						width: 2.5rem;
						height: 2.5rem;
						margin: 0.25rem 0;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
					}
					&.react-calendar__tile--now {
						background-color: unset;
						abbr {
							background-color: #ffff76;
							&:hover {
								background-color: #ffff76;
							}
						}
					}
					&:hover {
						background-color: unset;
						abbr {
							background-color: #e6e6e6;
						}
					}
				}
			}
		}
	}
	.select-time {
		display: flex;
		gap: .5rem;
		flex-wrap: wrap;
		padding-bottom: 2rem;
		border-bottom: 1px solid $border-grey-color;
		margin-bottom: 2rem;
		position: relative;
		.pass-ticket{
			position: absolute;
			top: -1rem;
			opacity: .7;
			left: 0;
			font-size: 1rem;
			z-index: 2;
			color: #000;
			font-weight: 600;
		}
		.single-time {
			border: 1px solid $border-grey-color;
			background-color: $sub-grey-color;
			border-radius: $border-radius;
			padding: 1rem 2.5rem;
			cursor: pointer;
			transition: 0.25s all;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			height: fit-content;
			&.active {
				background-color: $blue-color;
				color: #fff;
			}
			&.pass-time{
				margin-top: 1rem;
			}
			.time-tooltip {
				position: absolute;
				width: 13rem;
				height: 3rem;
				background-color: $grey-color;
				border-radius: $border-radius;
				top: -3.5rem;
				color: #000;
				display: flex;
				justify-content: center;
				align-items: center;
				display: none;
				z-index: 3;
				&::after {
					content: "";
					position: absolute;
					top: 100%;
					left: 50%;
					transform: translateX(-50%);
					border: 0.5rem solid transparent;
					border-top-color: $grey-color;
				}
			}
			&:hover {
				.time-tooltip {
					display: flex;
					animation: fade-in 0.5s;
				}
			}
			.once-time {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				.times{
					display: flex;
					align-items: center;
					span{
						&:first-child{
							width: 3.5rem;
						}
					}
				}
			}
		}
	}
	.select-type {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid $border-grey-color;
		margin-bottom: 2rem;
		position: relative;
		.type {
			margin-bottom: 2.25rem;
			padding-inline-end: 2.5rem;
			user-select: none;
			.name{
				text-transform: capitalize;
				user-select: none;
				margin-bottom: 1rem;
			}
			p {
				display: flex;
				flex-direction: column;
				user-select: none;
				span {
					opacity: 0.7;
					font-size: 0.75rem;
					user-select: none;
				}
			}
			&:last-child {
				padding-inline-end: unset;
			}
		}
		.counter {
			display: flex;
			border: 1px solid $border-grey-color;
			border-radius: $border-radius;
			overflow: hidden;
			div {
				height: 3.25rem;
				font-weight: 700;
				font-size: 0.875rem;
				transition: 0.25s all;
				min-width: 3rem;
				display: flex;
				justify-content: center;
				align-items: center;
				&.sign {
					font-size: 1rem;
					background-color: $sub-grey-color;
					cursor: pointer;
					position: relative;
					&.disabled {
						cursor: unset;
						opacity: .5;
						&:hover {
							color: #000;
							background-color: $sub-grey-color;
						}
					}
					&:hover {
						color: #fff;
						background-color: $blue-color;
					}
					span{
						user-select: none;
					}
				}
				input {
					border: unset;
					outline: unset;
					width: 100%;
					font-family: inherit !important;
					font-weight: 700;
					font-size: 0.875rem;
					width: 4rem;
					text-align: center;
				}
			}
			input[type="number"]::-webkit-inner-spin-button,
			input[type="number"]::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			input[type="number"] {
				-moz-appearance: textfield; /* Firefox */
			}
		}
		.numbers-errors {
			position: absolute;
			bottom: -1rem;
			color: red;
			font-size: 0.85rem;
		}
	}
	.selection-wrapper {
		padding-bottom: 2rem;
		border-bottom: 1px solid $border-grey-color;
		margin-bottom: 2rem;
		.select-container {
			width: 100%;
		}
	}
	.form-container {
		border-radius: $border-radius;
		height: fit-content;
		form {
			.form-group {
				display: flex;
				gap: 1rem;
				width: 100%;
				margin-bottom: 1.75rem;
				.input-wrapper {
					position: relative;
					flex: 1;
					// overflow: hidden;
					.PhoneInput {
						overflow: hidden;
						font-family: "public-font" !important;
						.PhoneInputCountry {
							position: absolute !important;
							width: 5rem;
							height: 100%;
							border-top-left-radius: 12px;
							border-bottom-left-radius: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
							gap: 0.5rem;
							background-color: #f6f6f6;
							border: 1px solid $border-grey-color;
							.PhoneInputCountrySelectArrow {
								opacity: 1;
								width: 0.35rem;
								height: 0.35rem;
								color: #000 !important;
							}
							.PhoneInputCountryIcon {
								border: none !important;
								box-shadow: none !important;
							}
						}
						input {
							padding-inline-start: 6rem;
							overflow: hidden;
							height: 100%;
						}
					}
					label {
						display: none;
					}
					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					/* Firefox */
					input[type="number"] {
						-moz-appearance: textfield;
					}
					input {
						font-family: "public-font" !important;
						font-weight: 600;
						padding: 1.25rem 1rem;
						width: 100%;
						border-radius: $border-radius;
						border: 1px solid $border-grey-color;
						&:focus-visible,
						&:focus {
							outline: none;
							border: 1px solid $border-grey-color;
						}
						&::placeholder {
							color: $grey-color;
							font-weight: 500;
							font-size: 0.85rem;
						}
					}
					.error {
						position: absolute;
						color: red;
						font-size: 0.75rem;
						padding: 0.25rem 0.25rem 0;
					}
				}
				&:last-child {
					justify-content: flex-end;
					margin-top: 2.5rem;
					margin-bottom: 0;
				}
				&.last {
					border-bottom: 1px solid $border-grey-color;
					padding-bottom: 2.5rem;
				}
				&.button-group {
					justify-content: space-between;
				}
			}
		}
	}
	.button-section {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
@media (min-width: 1024px) {
	.calender-summary-wrapper {
		width: 49.25%;
	}
	.widget-calender {
		padding: 3rem 2rem;
		.select-time {
			gap:1rem
		}
		.selection-wrapper {
			.select-container {
				width: 50%;
			}
		}
		.form-container {
			form {
				h4 {
					font-size: 1.2rem;
				}
				.form-group {
					margin-bottom: 2rem;
					gap: 2rem;
				}
			}
		}
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
