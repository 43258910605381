.info-container {
	display: flex;
	flex-direction: column;

	// gap: 1rem;
	.form-container {
		background-color: #fff;
		border-radius: $border-radius;
		padding: 2rem 1rem;
		height: fit-content;

		&.payment {
			h4 {
				margin-bottom: 1rem;
			}

			.stripe-form {
				.form-group {
					margin-top: 2rem;
				}
			}

			.button-group {
				justify-content: flex-end !important;

				&.mobile-form {
					flex-direction: column;
					gap: 2rem;
					align-items: flex-end;

					.button-container {
						width: 100%;
					}
				}
			}
		}

		h4 {
			margin-bottom: 2rem;

			&:first-child {
				margin-bottom: 1.5rem;
			}
		}

		.promo-code-container {
			margin-bottom: 2rem;
			position: relative;

			.code-input {
				position: relative;
			}

			.apply {
				position: absolute;
				right: calc(50% + 1.667rem);
				top: 1.25rem;
				cursor: pointer;
				color: $blue-color;

				&.check {
					cursor: unset;
					opacity: .5;
				}
			}

			.code-validation {
				position: absolute;
				left: 0;
				bottom: -1.5rem;
				cursor: pointer;
				color: $blue-color;

				&.error {
					color: red;
				}
			}

			input {
				padding: 1.25rem 1.667rem;
				width: 50%;
				border-radius: $border-radius;
				border: 1px solid $border-grey-color;
				font-family: "public-font" !important;
				font-weight: 500;

				&:focus-visible,
				&:focus {
					outline: none;
					border: 1px solid $border-grey-color;
				}

				&::placeholder {
					color: $border-grey-color;
					font-weight: 500;
					font-size: 1rem;
				}
			}
		}

		form {
			.form-group {
				&.radio {
					margin-bottom: 1.5rem;
					margin-top: -0.25rem;

					.input-wrapper {
						label {
							display: flex;
							align-items: center;

							span {
								// font-weight: 600;
								padding-inline-end: 2px;
								font-family: "public-font" !important;
							}
						}

						.radio-group {
							flex-direction: row;
							gap: 2rem;
							font-family: "public-font" !important;
						}
					}
				}

				&.promo {
					margin-bottom: 2rem !important;
				}

				display: flex;
				gap: 1rem;
				width: 100%;
				margin-bottom: 1.5rem;

				.input-wrapper {
					position: relative;
					flex: 1;

					.rmdp-container {
						width: 100%;
						cursor: pointer;

						input {
							cursor: pointer;
						}

						.rmdp-input {
							height: unset;
						}
					}

					.PhoneInput {
						overflow: hidden;
						font-family: "public-font" !important;

						.PhoneInputCountry {
							position: absolute !important;
							width: 5rem;
							height: 100%;
							border-top-left-radius: 12px;
							border-bottom-left-radius: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
							gap: 0.5rem;
							background-color: #f6f6f6;
							border: 1px solid $border-grey-color;

							.PhoneInputCountrySelectArrow {
								opacity: 1;
								width: 0.35rem;
								height: 0.35rem;
								color: #000 !important;
							}

							.PhoneInputCountryIcon {
								border: none !important;
								box-shadow: none !important;
							}
						}

						input {
							padding-inline-start: 6rem;
							overflow: hidden;
							height: 100%;
						}
					}

					label {
						display: none;
					}

					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					/* Firefox */
					input[type="number"] {
						-moz-appearance: textfield;
					}

					input {
						padding: 1.25rem 1.667rem;
						width: 100%;
						border-radius: $border-radius;
						border: 1px solid $border-grey-color;
						font-family: "public-font" !important;
						font-weight: 500;

						&:focus-visible,
						&:focus {
							outline: none;
							border: 1px solid $border-grey-color;
						}

						&::placeholder {
							color: $border-grey-color;
							font-weight: 500;
							font-size: 1rem;
						}
					}

					.error {
						position: absolute;
						color: red;
						font-size: 0.75rem;
						padding: 0.25rem 0.25rem 0;
					}

					.icon {
						position: absolute;
						right: 1.667rem;
						top: 50%;
						transform: translateY(-50%);
						opacity: .5
					}
				}

				&:last-child {
					justify-content: flex-end;
					margin-top: 0rem;
					margin-bottom: 0;
				}

				&.last {
					// border-bottom: 1px solid $border-grey-color;
					padding-bottom: 0rem;
				}

				&.button-group {
					justify-content: space-between;
					margin-top: 2rem !important;
				}

				&:empty {
					margin-bottom: 0;
				}
			}

			.info-form-group {
				margin-bottom: 1rem;
			}

			.form-header {
				text-transform: capitalize;
			}

			.payment-amount {
				margin-bottom: 2rem;
				padding-top: 2rem;
				border-top: 1px solid $border-grey-color;

				label {
					width: fit-content;
				}
			}
		}
	}

}

@media screen and (max-width: 600px) {
	.button-group {
		justify-content: space-between;
		margin-top: 2rem !important;
		gap: 1rem;

		.button-container  {
			width: 100%;
		}
	}
	.promo-code-container {
		input {
			width: 100% !important;
		}
		.apply {
			right: calc(0% + 1.667rem) !important;
		}
	}

  }

  @media screen and (max-width: 1023px) {
	.promo-code-container {
		input {
			width: 100% !important;
		}
		.apply {
			right: calc(0% + 1.667rem) !important;
		}
	}
	
  }

@media (min-width: 1024px) {
	.info-container {
		width: 66%;
		gap: 1.25%;

		.form-container {
			width: 100%;
			padding: 2rem 2.5rem;

			h4 {
				font-size: 1.2rem;
			}

			form {
				.form-group {
					margin-bottom: 2rem;
					gap: 2rem;

					&:last-child {
						margin-top: 0rem;
					}
				}
			}

			&.payment {
				h4 {
					margin-bottom: 1rem;
				}

				.stripe-form {
					.stripe-form-elements {
						padding-inline-end: 15rem;
					}

					.form-group {
						margin-top: 2rem;
						justify-content: space-between !important;
						align-items: center;
					}
				}

				.button-group {
					&.mobile-form {
						flex-direction: row;
						align-items: center;

						.button-container {
							width: unset;
						}
					}
				}
			}
		}
	}
}