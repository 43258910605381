.summary-container {
  border-radius: $border-radius;
  padding: 2rem $padding-mob;
  height: fit-content;

  .swiper-container {
    background-color: #fff;
    padding: 1rem;
    border-radius: $border-radius;
    margin-top: 2rem;

    .header-container {
      margin: 2rem 0 1rem;

      h1 {
        font-size: 1.45rem;
        margin-bottom: 1rem;
      }

      p {
        line-height: 1.5;
      }
    }
   

    .swiper-summary-section {
      position: relative;

      img {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        width: 100%;
        height: 15rem;
      }

      .arrows-container {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        z-index: 1;
        display: flex;
        gap: 0.5rem;

        .swiper-button {
          background-color: #fff;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &.swiper-button-disabled {
            opacity: 0.7;
            cursor: unset;

            svg {
              opacity: 0.5;
            }
          }
        }
      }
    }

    .thumb-swiper-container {
      margin-top: 0.5rem;

      .img-container {
        width: 100%;
        height: 5rem;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }

        .overlay {
          display: none;
        }

        &.active {
          .overlay {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba($color: #fff, $alpha: 0.5);
          }
        }
      }
    }
  }

  .booking-summary {
    h4 {
      font-size: 1.1rem;
      margin-bottom: 2rem;
    }
    p{
      font-size: 0.7rem;
      span {
        font-size: 0.7rem !important;
      }
    }
  
    .details-container {
      background-color: #fff;
      border-radius: $border-radius;
      padding-inline: 1rem;
      .tickets {
        border-bottom: 1px solid $border-grey-color;
        padding: 1rem 0;
        flex-wrap: wrap;
		justify-content: space-between;
        display: flex;
        .item {
          &:last-child {
            text-align: end;
          }
          p {
            font-weight: 700;
            margin-top: 0.5rem;
            &.ticket {
              text-transform: capitalize;
            }
          }
        }
      }

      .data {
        border-bottom: 1px solid $border-grey-color;
        display: flex;
        padding: 1rem 0;
        flex-wrap: wrap;
        // new css
        justify-content: space-between;
        align-items: flex-start;
        text-align: center;

        .item:nth-child(1){
          text-align: start;
        }
        .item:nth-child(3){
          text-align: end;
        }



        &.price {
          flex-direction: column;
          gap: 0.5rem;
        }

        &.users-details {
          .item {
            &.extra {
              width: fit-content;
            }
            p{
              text-transform: capitalize;

            }
          }
        }

        &:last-child {
          border: none;
        }

        .item {
          flex: 1;

          &.promo-code {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            p {
              &.promo {
                color: $red-color;
              }
            }
          }

          &.extra {
            width: 100%;
          }

          p {
            font-weight: 700;
            margin-top: 0.5rem;

            &.ticket {
              text-transform: capitalize;
            }
          }

          .extra-data {
            display: flex;
            justify-content: space-between;
            width: 100%;

            p {
              text-transform: capitalize;
            }
          }

          &.price {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  display: block;

  &.mobile {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0;
    padding-top: 2rem;
    z-index: 2;
    background-color: transparent;

    &.opened {
      max-height: 100%;
      overflow-y: scroll;
      z-index: 2;

      .data-wrapper {
        transform: translateY(0);
        position: relative;
        padding: 2rem $padding-mob;
        overflow-y: scroll;
      }

      .close-button-container {
        svg {
          transform: rotate(0deg);
        }
      }

      .footer {
        border-top: 0;
        gap: 16px;
        width: 100%;
         .title {
          width: 60%;
         }
         .total {
          width: 40%;

          .price {
            padding-left: 16px;
            border-left: 1px solid rgba(0, 0, 0, 0.10);
          }
         }
      }
    }

    .data-wrapper {
      background-color: $sub-grey-color;
      transform: translateY(100rem);
      transition: 0.25s all;
      position: absolute;
      z-index: 1;
    }

    .footer {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0.75rem 1.5rem;
      position: relative;
      z-index: 1;
      border-top: 1px solid $border-grey-color;
      gap: 16px;
      width: 100%;

      .title {
        width: 60%;
        h1 {
          margin-top: 0.25rem;
          font-size: 1.3rem;
        }
        .total {
          width: 40%;
  
          .price {
            padding-left: 16px;
            border-left: 1px solid rgba(0, 0, 0, 0.10);
          }
        }

        p {
          font-size: 0.75rem;
        }
      }


      .total {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        align-items: flex-end;
        width: 40%;

        .price {
          padding-left: 16px;
          border-left: 1px solid rgba(0, 0, 0, 0.10);
        }

        span {
          font-size: 0.75rem;
        }

        p {
          text-align: end;
        }
      }
    }

    .close-button-container {
      position: absolute;
      top: 0.5rem;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.25s all;
      padding: 0;
      width: 3rem;
      height: 3rem;
      border: 1px solid $border-grey-color;
      border-radius: 50%;

      svg {
        transition: 0.25s all;
        transform: rotate(180deg);
      }
    }
  }
}

.website-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba($color: #000, $alpha: 0.4);
  left: 0;
}

.text-end{
  text-align: end !important;
}

.second-title {
  color: rgba(33, 29, 51, .4);
  font-size: .6rem;
}

@media screen and (max-width: 600px) {
  .summary-exp-ttile {
    font-size: 1rem !important;
  }
}

@media (min-width: 1024px) {
  .summary-container {
    width: 33%;
    padding: 2rem $padding-desk;
    background-color: $sub-grey-color;

    .swiper-container {
      display: block;

      .swiper-summary-section {
        img {
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
          width: 100%;
          height: 14rem;
        }
      }
    }

    .only-quantity{
      width: 33% !important;
      align-items: center !important;
      margin-left: 0 !important;
      text-align: center !important;
    }
    .d-content {
      display: contents !important;
    }
    .w-33 {
      width: 33% !important;
    }
    .only-quantity-perperson-price{
      width: 100% !important;
      text-align: center !important;
      display: flex;
      justify-content: center;
      font-size: 0.7rem;
    }

    .text-end{
      text-align: end !important;
    }

    .booking-summary {
      h4 {
        font-size: 1.2rem;
      }

      .details-container {
        .data {
          column-gap: 2rem;
          row-gap: 1.5rem;

          .item {
            &:nth-child(2) {
              text-align: center;
            }
            &:nth-child(3) {
              text-align: end;
            }
            span {
              font-size: 0.85rem;
              font-weight: 700;
            }
          }
        }
        .data-extras {
          display: flex;
          flex-direction: column;
          row-gap: 12px;
          

          .item-extra {
            width: 100%;
            justify-content: space-between;
            display: flex;

            .quantity-extra-title{
              margin-left: 20px;
            }

            .quantity-extra {
              margin-left: 90px;
            }
            .ml-4 {
              margin-left: 20px;
            }
           

          .per-person-price {
            display: flex;
            flex-direction: column;
            width: 20%;
            p {
              text-align: end !important;
              width: 100%;
            }
          }

            .extra-option-title {
              display: flex;
              flex-direction: column;
              p {
                text-align: start !important;
              }
              span {
                width: 100%;
                text-align: start !important;
                color: rgba(33, 29, 51, 0.4);
							  font-size: 0.65rem;
              }
            }
            
            
            span:nth-child(2) {
              text-align: start;
            }
        
           
            p {
              width: 33%;
            }
            div {
              width: 33%;
              p {
                width: 100%;
                text-align: center !important;
              }
            }
            p:nth-child(2) {
              text-align: center;
              flex: 1; 
            }
        
            p:nth-child(3) {
              text-align: right;
            }

            
          }
        }
      }
    }

    .payment-summary-per-person-price {
      width: 33% !important;
    }
    .quantity-extra-title2{
      margin-left: 20px;
    }

    .ml-16{
      margin-left: 64px;
    }

    .footer {
      display: none;
    }

    .close-button-container {
      display: none;
    }
  }
}

@media (max-width: 1023px) {
.data-extras {
  display: flex;
  gap: 12px;
  flex-direction: column;}

  .item-extra{
    justify-content: space-between;
    display: flex;
    flex: 1 1;
    width: 100%;

    .bold:nth-child(1){
      width: 33%;
      text-align: start;
    }
    .bold:nth-child(2){
      width: 33%;
    }
    .bold:nth-child(3){
      width: 33%;
      text-align: end;
    }

    span:nth-child(3)  {
      width: 33%;
    }
  }

  .ml-4{
    margin-right: 90px;
  }
  .per-person-price{
    width: 33%;
  }
  .extra-option-title{
    width: 33%;
    text-align: start;
    display: flex;
    flex-direction: column;
  }
  .quantity-sm {
    width: 33%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  
  .price-sm {
    width: 33%;
    text-align: end;
  }

}