.confirm-booking-container {
	p{
		font-size: 0.7rem;
	}
	padding: 2rem $padding-mob;
	background-color: #fff;
	border-radius: $border-radius;
	.header {
		display: flex;
		gap: 1.5rem;
		align-items: center;
		padding-bottom: 1.667rem;
		border-bottom: 1px solid $sub-grey-color;
		img {
			width: 2.5rem;
			height: 2.5rem;
		}
		.header-content {
			width: 100%;
			h1 {
				font-size: 1.25rem;
				margin-bottom: 0.5rem;
			}
			span {
				font-size: 0.75rem;
			}
			.print{
				display: flex;
				align-items: center;
				cursor: pointer;
				gap: .25rem;
				span {
					font-size: 0.85rem;
				}
			}
		}
	}
	.booking-details {
	
		padding: 2.25rem 0;
		border-bottom: 1px solid $sub-grey-color;
		display: flex;
		flex-direction: column;
		gap: 1.67rem;
		&.print-details{
			border: none;
			padding-bottom: 0;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			.print {
				display: flex;
				gap: 0.5rem;
				align-items: center;
				justify-content: center;
				color: $blue-color;
				align-items: center;
				cursor: pointer;
			}
		}
		h2 {
			font-size: 1.15rem;
		}
		.order-data {
			display: flex;
			flex-wrap: wrap;
			gap: 2rem;
			.item {
				span {
					font-size: 0.75rem;
					text-transform: uppercase;
				}
				p {
					margin-top: 0.5rem;
					text-transform: capitalize;
				}
				p.email {
					margin-top: 0.5rem;
					text-transform: none;

				}
				.extra-name{
					height: 28px;
					display: inline-block;
				}   
			
			}
		}
		.details {
			font-size: 0.85rem;
			display: flex;
			gap: 0.5rem;
			align-items: center;
			p {
				color: $blue-color;
				text-transform: capitalize;
			}
		}
	}
}
.summary-confrim-wrapper{
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	margin-bottom: 6rem;
}

@media screen and (max-width: 500px) {
	.header-content {
		h1 {
			font-size: 0.95rem !important;
			font-weight: 800 !important;
		}
	}
	.order-data {
		flex-direction: column;
	}
  }

@media (min-width: 1024px) {
	.summary-confrim-wrapper{
		flex-direction: row;
		gap: 1.25%;
		margin-bottom: 0rem;
	}
	.confirm-booking-container {
		width: 66%;
		// width: 100%;
		padding: 3rem 2.75rem;
		height: fit-content;
		.header {
			img {
				width: 3.5rem;
				height: 3.5rem;
			}
			.header-content {
				h1 {
					font-size: 1.6rem;
				}
				span {
					font-size: 1rem;
				}
				.header-description{
					display: flex;
					justify-content: space-between;
				}
			}
		}
		.booking-details {
			h2 {
				font-size: 1.11rem;
			}
			.order-data {
				column-gap: 5rem;
				row-gap: 2rem;
				display: grid;
				grid-template-columns: repeat(4, 1fr); 
			}
			.details {
				font-size: 1rem;
			}
		}
	}
}

@media print{
	.confirm-booking-container{
		.booking-details {
			&:last-child{
				.print{
					display: none;
				}
			}
		}
	}
	.summary-container {
		display: none !important;
	}
}
